
import { VueDraggableNext } from 'vue-draggable-next';

import { ref, reactive, computed } from 'vue';

import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

import draggable from 'vuedraggable';

import Button from 'primevue/button';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

import { Loader } from '../../../components/ui';
import { useStores } from '@/store/Stores';
import Checkbox from 'primevue/checkbox';

export default {
  components: {
    Button,
    Loader,
    VueDraggableNext,
    Checkbox
  },
  setup() {
    const { companyStore, companyStoreState } = useStores();

    const { t } = useI18n();
    const toast = useToast();

    const isChangePassword = ref(false);
    const data = computed(() => companyStoreState.data);

    const notChanged = ref(true);

    const rules = reactive({
      title: { required },
      email: { required, email },
      name: { required },
      phone: { required },
      address: { required },
      credits: {},
      tokens: {},
      is_active: {}
    });

    const v$ = useVuelidate(rules, data);

    const handleSubmit = (isFormValid) => {
      v$.value.$touch();
      if (!isFormValid) {
        return;
      }

      companyStore.saveChanges(data.value, toast, t);
    };

    const toggleBlock = () => {
      companyStore.toggleBlock();
    };

    const toggleSelectAngle = (e: Event, angle: any) => {
      let target: HTMLElement = e.target as any;

      while (target.nodeName !== 'BODY') {
        if (target.nodeName === 'LABEL' && target.classList.contains('p-field-checkbox')) {
          return;
        }

        target = target.parentElement;
      }

      angle.isSelected = !angle.isSelected;

      notChanged.value = false;
    };

    const onDragChange = () => {
      notChanged.value = false;
    };

    const onToggleWithLogo = (e) => {
      notChanged.value = false;
    };

    const onToggleWithBanner = (e) => {
      notChanged.value = false;
    };

    return {
      v$,
      state: companyStoreState,
      isChangePassword,
      notChanged,
      draggable,
      handleSubmit,
      onDragChange,
      toggleBlock,
      toggleSelectAngle,

      onToggleWithLogo,
      onToggleWithBanner
    };
  }
};
